.navbar_container {
    background-color: black;
    color: white;
    /* display: flex; */
    /* justify-content: space-between; */
    text-align: center;
    /* align-items: center; */
    padding: 5px 7%;
    /* flex-direction: column; */
}

.navbar_left {
    background-color: white;
    border: 1px solid white;    
    height:80px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:80px;
    padding: 3px;
    font-size: 33px;
    font-weight: 700;
    color: black;
    display: inline-flex;
    align-items: center; 
    justify-content: center;
}

.navbar_right {
    /* padding-top: 10px;*/
    /* margin-left: 45px;  */
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
}

.navbar_right > a {
    margin: 0 60px;
    font-size: 25px;
    text-align: center;
    text-decoration: none;
    color: white;
}

.navbar_right > a:hover {
    border-style: solid;
    border-color: white;
    border-radius: 16px;
    background-color: bisque;
    color: black;
    cursor: pointer;
}