.projects_title {
    background-color: white;
    color: black;
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding-top: 25px;
}
.projects_box_container {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}